<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Login</a></li>
        </ul>
      </div>
    </div>
    <section class="section--login">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 form-group--block offset-sm-3">
            <div class="login__box">
              <div class="login__header">
                <h3 class="login__login">Login</h3>
              </div>
              <div class="login__content">
                <div class="login__label">Login to your account.</div>
                <form @submit.prevent="onSubmit(email, password)">
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Email address"
                      v-model="email"
                      :class="{ 'is-invalid': $errors.has('email') }"
                    />
                  </div>
                  <div class="invalid-feedback" v-show="$errors.has('email')">
                      {{ $errors.first("email") }}
                    </div>
                  <div class="input-group group-password">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      v-model="password"
                      :class="{ 'is-invalid': $errors.has('password') }"
                    />                    
                    <div class="input-group-append">
                      <button class="btn forgot-pass" type="button">
                        <router-link :to="{ name: 'forgot-password' }"
                          >Forgot?</router-link
                        >
                      </button>
                    </div>
                  </div>
                  <div class="invalid-feedback" v-show="$errors.has('password')">
                      {{ $errors.first("password") }}
                    </div>
                  <div class="">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="checkbox0"
                    />
                    <label for="checkbox0" class="form-check-label"
                      >Remember me</label
                    >
                  </div>
                  <button class="btn btn-login" type="submit" :disabled="processing">{{processing ? 'Please wait...' : 'Login'}}</button>
                </form>
                  
                  <div style="font-size:14px">
                    Don't you have an account? 
                    <router-link :to="{ name: 'register' }">
                      Register
                    </router-link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      email: null,
      password: null,
    };
  },

  methods: {
    onSubmit(email, password) {
      this.$store
        .dispatch("auth/login", { email, password })
        .then(() => {
          if(this.isAuthenticated){
            this.$router.push(this.$route.query.from || '/')
          }

          // if (this.user.is_cart) {
          //   this.$router.push({ name: "cart" });
          // }else{
          //   this.$router.push({ name: "home" });
          // }
        });
    },
  },

  computed: {
    ...mapGetters("auth", ["user",'processing', 'isAuthenticated']),
  },
};
</script>